import React, {useCallback, useEffect, useState} from 'react'

import useSWR from 'swr'
import cn from 'classnames'
import Link from 'next/link'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {Container, media} from 'styled-bootstrap-grid'
import useEmblaCarousel from 'embla-carousel-react'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import {fluidRange} from '@festi/common/utils/styles'
import {useUserPrices} from '@festi/common/utils/rest'
import settings from '@festi/common/constants/settings'
import {handleRestResponse, restApi} from '@festi/common/api/rest'
import {Page_page_content_ProductCarouselRecord} from '@festi/common/api/datocms/types/Page'

import {BlockWrapper} from 'src/components/layout'
import NewProductCard from 'src/components/cards/redesign/NewProductCard'
import ProductCardLoader from 'src/components/cards/redesign/utilities/ProductCardLoader'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const Title = styled.div`
  ${fluidRange('font-size', '20px', '24px')};
  font-weight: 500;
  color: ${palette('blue')};
`

const Count = styled.span`
  ${fluidRange('font-size', '14px', '16px')};
  color: ${palette('blue')};
`

const TextLink = styled(Link)`
  font-size: 16px;
  line-height: 22px;
  padding-right: 0;
  color: ${palette('blue')};
  text-align: right;
  text-decoration: underline;

  ${media.sm`
    padding-right: 104px;
  `}
`

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Carousel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  height: 100%;
`

const ButtonWrapper = styled.div`
  position: absolute;
  display: none;
  top: -6px;
  right: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  z-index: 1;

  ${media.sm`
    display: flex;
  `}
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: ${palette('white')};
  cursor: pointer;

  &:hover {
    background-color: ${palette('ui5Solid')};
    border-color: ${palette('ui10Solid')};
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`

const EmblaContainer = styled.div`
  display: flex;
  width: 100%;
  ${fluidRange('gap', '8px', '10px')};
`

const EmblaSlider = styled.div`
  flex: 0 0 94%;
  min-width: 219px;

  ${media.sm`
    flex: 0 0 47.5%;
  `}

  ${media.md`
    flex: 0 0 calc(100% / 3 - 9px);
  `}

  ${media.lg`
    flex: 0 0 calc(100% / 4 - 9px);
  `}
`

const Progress = styled.div`
  position: relative;
  display: none;
  width: 100%;
  height: 2px;
  overflow: hidden;
  background-color: ${'#8086A8'};

  ${media.sm`
    display: flex;
  `}
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  border-radius: 2px;
  box-shadow: inset 0 0 0 0.2rem ${palette('ui10Solid')};
`

interface EmblaProps {
  children: React.ReactNode
}

function EmblaCarousel({children}: EmblaProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: 'start',
    containScroll: 'trimSnaps',
  })

  const [scrollProgress, setScrollProgress] = useState(0)

  const onScroll = useCallback(() => {
    if (!emblaApi) return
    const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()))
    setScrollProgress(progress * 100)
  }, [emblaApi])

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setPrevBtnDisabled(!emblaApi.canScrollPrev())
      setNextBtnDisabled(!emblaApi.canScrollNext())
    }
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    onScroll()
    emblaApi.on('select', onSelect)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('scroll', onScroll)
  }, [emblaApi, onSelect, onScroll])

  return (
    <>
      <ButtonWrapper>
        <Button
          className={cn('embla__prev', {disabled: prevBtnDisabled})}
          onClick={() => emblaApi.canScrollPrev() && emblaApi.scrollPrev()}
          disabled={prevBtnDisabled}
        >
          <ArrowLeftSLineIcon />
        </Button>

        <Button
          className={cn('embla__next', {disabled: nextBtnDisabled})}
          onClick={() => emblaApi.canScrollNext() && emblaApi.scrollNext()}
          disabled={nextBtnDisabled}
        >
          <ArrowRightSLineIcon />
        </Button>
      </ButtonWrapper>

      <Carousel className="embla" ref={emblaRef}>
        <EmblaContainer>{children}</EmblaContainer>

        <Progress>
          <ProgressBar
            className="embla__progress_bar"
            style={{transform: `translate3d(${scrollProgress}%,0px,0px)`}}
          />
        </Progress>
      </Carousel>
    </>
  )
}

export default function ProductCarousel({
  title,
  products,
}: Page_page_content_ProductCarouselRecord): JSX.Element {
  const skuMap = products?.map((p) => p?.sku)

  const {data: variants, isLoading} = useSWR(
    [skuMap, 'variants'],
    ([skus]) =>
      handleRestResponse(
        restApi.productVariantsList({
          sku: [skus.join(',')],
          channel: settings.channel,
        }),
      ),
    {revalidateOnFocus: false},
  )

  const {data: userPrices} = useUserPrices(skuMap)

  const variantCount = variants?.results?.length ?? 0

  return (
    <BlockWrapper>
      <Container>
        <Wrapper>
          <TitleWrapper>
            <LeftWrapper>
              <Title>{title}</Title>

              <Count>({variantCount})</Count>
            </LeftWrapper>

            {/* note-Bjork: Wait with this until we know how we are going to handle this
            <TextLink href="/oskalisti">Sjá allar vörur</TextLink> */}
          </TitleWrapper>

          <CarouselContainer className="embla_viewport">
            {isLoading ? (
              <EmblaCarousel>
                {[...Array(6)].map((_, i) => (
                  <EmblaSlider key={i}>
                    <ProductCardLoader />
                  </EmblaSlider>
                ))}
              </EmblaCarousel>
            ) : (
              !!variants?.results?.length && (
                <EmblaCarousel>
                  {variants?.results?.map((variant) => (
                    <EmblaSlider key={variant.id}>
                      <NewProductCard
                        variant={variant}
                        image={{
                          url: variant.firstImage.image.webpS,
                          jpg: variant.firstImage.image.jpgS,
                          alt: variant.firstImage.alt,
                        }}
                        userPrice={userPrices?.[variant.sku]}
                      />
                    </EmblaSlider>
                  ))}
                </EmblaCarousel>
              )
            )}
          </CarouselContainer>
        </Wrapper>
      </Container>
    </BlockWrapper>
  )
}
