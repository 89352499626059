import React from 'react'

import Link from 'next/link'
import {Image} from 'react-datocms'
import {palette} from 'styled-tools'
import styled from 'styled-components'

import {fluidRange} from '@festi/common/utils/styles'
import {Page_page_content_GridSystemRecord_card_ImageCardRecord_image} from '@festi/common/api/datocms/types/Page'

interface CardWrapperProps {
  textboxPosition: 'top' | 'middle' | 'bottom'
}
const position = {
  top: 'flex-start',
  middle: 'center',
  bottom: 'flex-end',
}

const CardWrapper = styled.div<CardWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({textboxPosition}) =>
    position[textboxPosition || 'bottom']};
  width: 100%;
  min-height: 336px;
  border-radius: 12px;
`

const CustomLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const BackgroundImage = styled(Image)`
  width: 100%;
  height: 100%;
  bottom: 0;
  border-radius: 12px;
  z-index: 0;
`

const Cover = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  ${fluidRange('padding-left', '16px', '24px')};
  ${fluidRange('padding-right', '16px', '24px')};
`

const Content = styled.div`
  height: 100%;
  background: transparent;
  border-radius: 12px;
  z-index: 9999;
`

const Frame = styled.div`
  content: '';
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  ${fluidRange('padding-left', '16px', '28px')};
  ${fluidRange('padding-right', '16px', '28px')};
  border-radius: 4px;
  gap: 4px;
  background: transparent;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    background: ${palette('white')};
    z-index: 1;
    transform: rotate(-1deg);
  }
`

const Title = styled.div`
  font-family: 'ElkjopSans', sans-serif;
  text-align: center;
  ${fluidRange('font-size', '20px', '24px')};
  ${fluidRange('line-height', '24px', '30px')};
  font-weight: 410;
  color: ${palette('blue')};
  z-index: 10;
`

const Tagline = styled.div`
  font-family: 'ElkjopSans', sans-serif;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: ${palette('ui70Solid')};
  z-index: 10;
  font-weight: 410;
`

interface Props {
  title: string | null
  path: string | null
  subtitle: string | null
  image: Page_page_content_GridSystemRecord_card_ImageCardRecord_image | null
  textboxPosition: 'top' | 'middle' | 'bottom' | null
}

export default function NewImageCard({
  title,
  path,
  image,
  subtitle,
  textboxPosition,
}: Props): JSX.Element {
  return (
    <CardWrapper textboxPosition={textboxPosition}>
      <CustomLink href={path || '#'}>
        <BackgroundImage
          data={image.responsiveImage}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          pictureStyle={{borderRadius: '12px'}}
        />

        <Cover>
          <Content>
            <Frame>
              <Title>{title}</Title>
              <Tagline>{subtitle}</Tagline>
            </Frame>
          </Content>
        </Cover>
      </CustomLink>
    </CardWrapper>
  )
}
