import React from 'react'

import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {BambuserCtaFields} from '@festi/common/api/datocms/types/BambuserCtaFields'
import {H2, PLarge} from '@festi/common/components/typography'
import VideoCall from '@festi/common/components/chat/VideoCall'
import {useAuth} from '@festi/common/contexts'

import {BlockWrapper} from '../layout'

const ButtonWrapper = styled.div`
  max-width: 300px;
  margin: auto;
`

const BambuserWrapper = styled.div`
  max-width: 800px;
  margin: auto;
`

export default function BambuserCta({
  title,
  description,
  loginLabel,
  videoCallLabel,
}: BambuserCtaFields): JSX.Element {
  const {user} = useAuth()

  return (
    <BlockWrapper>
      <Container>
        <Row>
          <Col>
            <BambuserWrapper>
              {!!title && (
                <H2 withGutter center>
                  {title}
                </H2>
              )}

              {!!description && (
                <PLarge withGutter center>
                  {description}
                </PLarge>
              )}

              <ButtonWrapper>
                <VideoCall label={!user?.id ? loginLabel : videoCallLabel} />
              </ButtonWrapper>
            </BambuserWrapper>
          </Col>
        </Row>
      </Container>
    </BlockWrapper>
  )
}
