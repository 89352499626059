import React, {useMemo} from 'react'

import Link from 'next/link'
import {Image} from 'react-datocms'
import {palette} from 'styled-tools'
import styled from 'styled-components'

import {fluidRange} from '@festi/common/utils/styles'
import {Page_page_content_GridSystemRecord_card_PromoCardRecord_image} from '@festi/common/api/datocms/types/Page'

const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 336px;
  border-radius: 12px;
  overflow: hidden;
`

const CustomLink = styled(Link)`
  height: 100%;
  color: inherit;
  text-decoration: none;
  overflow: hidden;
`

const BackgroundImage = styled(Image)`
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  z-index: 0;
`

const BackgroundColor = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${palette('lightBlue')};
  border-radius: 12px;
  z-index: 0;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const OverlayShape = styled.svg`
  position: absolute;
  ${fluidRange('top', '-150px', '-85px')};
  ${fluidRange('right', '25px', '128px')};
  z-index: 1;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 336px;
  ${fluidRange('padding', '30px', '40px')};
  border-radius: 12px;
  background: transparent;
  z-index: 10;
`

const TitleWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0;
  ${fluidRange('width', '150px', '350px')};
  z-index: 10;
`

const color = {
  white: palette('white'),
  blue: palette('blue'),
  pink: palette('pink'),
  green: palette('green'),
}
interface TitleProps {
  titleColor: 'white' | 'blue'
}

const Title = styled.div<TitleProps>`
  font-family: 'ElkjopHeadline', sans-serif;
  text-align: center;
  ${fluidRange('font-size', '48px', '100px')};
  ${fluidRange('line-height', '48px', '92px')};
  font-weight: 400;
  text-transform: uppercase;
  color: ${({titleColor}) => color[titleColor || 'white']};
  z-index: 10;
`

interface FrameProps {
  textboxColor: 'pink' | 'green'
}

const Frame = styled.div<FrameProps>`
  content: '';
  position: relative;
  align-items: flex-end;
  padding: 6px 12px 6px 12px;
  border-radius: 6px;
  background: transparent;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    background: ${({textboxColor}) => color[textboxColor || 'green']};
    z-index: -1;
    transform: rotate(-1deg);
  }
`
// ég virðist bara geta gert font-weight yfir 400. Design segir 150.
const Tagline = styled.div`
  text-align: center;
  ${fluidRange('font-size', '16px', '24px')};
  ${fluidRange('line-height', '16px', '30px')};
  font-weight: 410;
  color: ${palette('blue')};
`

interface TitleFrameProps {
  textboxColor: 'pink' | 'green'
  highlightedWordColor: 'white' | 'blue'
}

const TitleFrame = styled.span<TitleFrameProps>`
  content: '';
  position: relative;
  padding: 0 12px 0 12px;
  border-radius: 4px;
  background: transparent;
  color: ${({highlightedWordColor}) => color[highlightedWordColor || 'white']};
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    ${fluidRange('bottom', '8px', '16px')};
    border-radius: 6px;
    transform: rotate(-2deg);
    background: ${({textboxColor}) => color[textboxColor || 'green']};
    z-index: -1;
  }
`

interface Props {
  title: string | null
  path: string | null
  subtitle: string | null
  textboxColor: 'green' | 'pink' | null
  titleColor: 'white' | 'blue' | null
  highlightedWordColor: 'white' | 'blue' | null
  image: Page_page_content_GridSystemRecord_card_PromoCardRecord_image | null
}

export default function PromoCard({
  title,
  path,
  image,
  subtitle,
  textboxColor,
  titleColor,
  highlightedWordColor,
}: Props): JSX.Element {
  const titleWords = useMemo(() => {
    return title.split(' ').map((word, index) => (
      <React.Fragment key={index}>
        {index > 0 && ' '}
        {word.startsWith('*') && word.endsWith('*') ? (
          <TitleFrame
            textboxColor={textboxColor}
            highlightedWordColor={highlightedWordColor}
          >
            {word.slice(1, -1)}
          </TitleFrame>
        ) : (
          <span>{word}</span>
        )}
      </React.Fragment>
    ))
  }, [title, textboxColor, highlightedWordColor])

  return (
    <CardWrapper>
      <CustomLink href={path || '#'}>
        {image ? (
          <BackgroundImage
            data={image.responsiveImage}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            pictureStyle={{borderRadius: '12px'}}
          />
        ) : (
          <BackgroundColor />
        )}
        <Overlay>
          <OverlayShape
            width="1173"
            height="1013"
            viewBox="0 0 1173 1013"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.03"
              d="M746.94 0H0V1013H672.94C769.568 917.465 988.069 694.44 1109.29 566.621C1230.52 438.802 1154 334.5 1082.94 278.369C1011.88 222.238 746.94 0 746.94 0Z"
              fill="white"
            />
          </OverlayShape>

          <OverlayShape
            width="1115"
            height="998"
            viewBox="0 0 1115 998"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.1"
              d="M709.593 0H0V997.5H602C693.796 906.742 938.666 659.718 1053.83 538.29C1168.99 416.862 1096.38 318.981 1028.79 264.451C961.204 209.92 709.593 0 709.593 0Z"
              fill="white"
            />
          </OverlayShape>
        </Overlay>

        <Content>
          <TitleWrapper>
            <Title titleColor={titleColor}>{titleWords}</Title>
          </TitleWrapper>

          <Frame textboxColor={textboxColor}>
            <Tagline>{subtitle}</Tagline>
          </Frame>
        </Content>
      </CustomLink>
    </CardWrapper>
  )
}
