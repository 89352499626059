import React from 'react'

import {Image} from 'react-datocms'
import {useWindowSize} from 'react-use'
import MailLineIcon from 'remixicon-react/MailLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {EmployeeListFields_employees} from '@festi/common/api/datocms/types/EmployeeListFields'
import {H5} from '@festi/common/components/typography'
import {gridTheme, styledTheme} from '@festi/common/themes'
import settings from '@festi/common/constants/settings'

const newDesign = settings.redesign

interface EmailProps {
  email: string
}

const EmployeeCardWrapper = styled.div`
  display: flex;
  background-color: white;
  margin-bottom: 20px;
  border: 1px solid ${palette('ui20Solid')};
  border-radius: ${newDesign ? '12px' : 'unset'};
  overflow: hidden;
`
const EmployeeInfo = styled.div`
  padding: 13px;

  ${media.sm`
    padding: 24px;
  `}

  ${media.md`
    padding: 30px;
  `}
`

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${palette('lightBlue')};
  margin: 8px 0;

  ${media.md`
    margin: 20px 0;
  `}
`
const EmailSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  svg {
    margin-top: 2px;
    margin-right: 10px;
  }

  ${media.md`
    margin-top: 20px;
  `}
`

const EmailLink = styled.a`
  transition: color 0.15s;
  font-size: 14px;

  &:hover {
    color: ${palette('lightBlue')};
  }

  ${media.md`
    font-size: 16px;
`}
`

export function EmployeeEmail({email}: EmailProps) {
  return (
    <EmailSection>
      <MailLineIcon size={18} color={styledTheme.palette.lightBlue} />
      <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
    </EmailSection>
  )
}

export default function EmployeeCard({
  name,
  title,
  email,
  image,
}: EmployeeListFields_employees) {
  const {width} = useWindowSize()
  const isMobile = width < gridTheme.breakpoints.md

  return (
    <EmployeeCardWrapper>
      {!!image && (
        <Image data={isMobile ? image.mobileImg : image.desktopImg} />
      )}
      <EmployeeInfo>
        <H5>{name}</H5>
        <Line />
        <H5>{title}</H5>
        <EmployeeEmail email={email} />
      </EmployeeInfo>
    </EmployeeCardWrapper>
  )
}
