import {useCallback} from 'react'

import Link from 'next/link'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import ShoppingBasket2LineIcon from 'remixicon-react/ShoppingBasket2LineIcon'

import {WishlistItem} from '@festi/common/api/rest'
import {ProductPrices} from '@festi/common/components/cart/ProductPrices'
import {getListingPrice} from '@festi/common/utils/price'
import {AugmentedUserPrice} from '@festi/common/utils/rest'
import {useWishlist} from '@festi/common/utils/wishlists'
import {MiniAddToCartButton} from '@festi/common/components/cart'
import {CloseButton} from '@festi/common/components/buttons'
import {useCommonContext} from '@festi/common/contexts/common'

import ProductTags from '../utilities/ProductTags'
import ProductActionButtons from '../utilities/ProductActionButtons'
import WebpImage from 'src/components/common/WebpImage'

const VariantCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${palette('white')};
  border-radius: 12px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    border-radius: 12px;
    pointer-events: none;
    z-index: 1;
  }

  &:hover:before {
    border-color: ${palette('ui20Solid')};
  }
`

const VariantCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 12px 20px;
  gap: 20px;
`

const Close = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 138px;
`

const VariantDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
`

const VariantCardInfoAnchor = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
`

const VariantCardName = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: ${palette('blue')};
  text-align: left;
`

const VariantCardSku = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${palette('ui40Solid')};
  text-align: left;
`

const FunctionWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
`

const ProductActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
`

interface Props {
  item: WishlistItem
  userPrice?: AugmentedUserPrice
  userPriceLoading?: boolean
  showRemoveButton?: boolean
  showAddToCartButton?: boolean
  showProductActionButtons?: boolean
  showOnlyCompareIcon?: boolean
  onClick?: () => void
}

export default function LargeWishlistCard({
  item,
  userPrice,
  showRemoveButton,
  showAddToCartButton,
  showProductActionButtons,
  showOnlyCompareIcon,
  onClick,
}: Props) {
  const {firstImage, sku, name, product} = item.variant
  const price = getListingPrice(item.variant)

  const {removeVariantFromWishlist} = useWishlist()
  const {handleCopyText} = useCommonContext()

  const onClickRemove = useCallback(() => {
    removeVariantFromWishlist(item.sku)
  }, [removeVariantFromWishlist, item.sku])

  return (
    <VariantCardWrapper>
      <ProductTags variant={item.variant} />

      <VariantCardContent>
        {showRemoveButton && (
          <Close>
            <CloseButton onClick={onClickRemove} />
          </Close>
        )}

        <VariantDetails>
          <Link href={`/vorur/${product.slug}/${sku}`}>
            <ImageWrapper>
              <WebpImage
                webp={firstImage?.image?.webpS}
                jpg={firstImage?.image?.jpgS}
                alt={firstImage?.alt || name}
              />
            </ImageWrapper>
          </Link>

          <VariantCardInfoAnchor
            href={`/vorur/${product.slug}/${sku}/`}
            onClick={onClick}
          >
            <VariantCardName>{name}</VariantCardName>
            <VariantCardSku onClick={(e) => handleCopyText(e, sku)}>
              {sku}
            </VariantCardSku>
          </VariantCardInfoAnchor>
        </VariantDetails>

        <FunctionWrapper>
          <PriceWrapper>
            <ProductPrices
              price={userPrice?.price?.price || price?.price || 0}
              lowestPrice={userPrice?.lowestPrice || price?.lowestPrice || 0}
              discountedPrice={userPrice?.userPrice || price?.discountedPrice}
              size="24px"
              oldPriceSize="16px"
            />
          </PriceWrapper>

          <ProductActionButtonsWrapper>
            {showProductActionButtons && (
              <ProductActionButtons
                variant={item.variant}
                disableWishlist={showOnlyCompareIcon}
              />
            )}

            {showAddToCartButton && (
              <MiniAddToCartButton
                icon={ShoppingBasket2LineIcon}
                variant={item.variant}
                newDesign
              />
            )}
          </ProductActionButtonsWrapper>
        </FunctionWrapper>
      </VariantCardContent>
    </VariantCardWrapper>
  )
}
