import React from 'react'

import useSWR from 'swr'

import {AugmentedUserPrice} from '@festi/common/utils/rest'
import {handleRestResponse, restApi} from '@festi/common/api/rest'

import NewProductCard from './NewProductCard'

interface Props {
  sku: string
  userPrice?: AugmentedUserPrice
}

export default function SingleProductCard({
  sku,
  userPrice,
}: Props): JSX.Element {
  const {data: variant} = useSWR([sku, 'variant'], () =>
    handleRestResponse(restApi.productVariantsRetrieve(sku)),
  )

  if (variant === undefined) return <div>Loading...</div>

  return (
    <NewProductCard
      variant={variant}
      image={{
        url: variant.images?.[0]?.image.webpS,
        jpg: variant.images?.[0]?.image.jpgS,
        alt: variant.images?.[0]?.alt,
      }}
      userPrice={userPrice}
    />
  )
}
