import React from 'react'

import Link from 'next/link'
import styled from 'styled-components'
import {Image} from 'react-datocms'
import {palette} from 'styled-tools'
import {fluidRange} from '@festi/common/utils/styles'
import {Page_page_content_GridSystemRecord_card_LargeImageCardRecord_image} from '@festi/common/api/datocms/types/Page'

const CardWrapper = styled.div`
  ${fluidRange('height', '400px', '520px')};
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  background-color: ${palette('blue')};
`

const Inner = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 20px 20px 0 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
  ${fluidRange('padding-left', '0px', '64px')};
  z-index: 99;

  @media (max-width: 768px) {
    gap: 20px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 768px) {
    gap: 20px;
  }
`

const Title = styled.div`
  font-family: 'ElkjopHeadline', sans-serif;
  text-align: left;
  ${fluidRange('font-size', '32px', '76px')};
  ${fluidRange('line-height', '34px', '76px')};
  font-weight: 0;
  color: ${palette('white')};
  text-transform: uppercase;
`

const Subtitle = styled.div`
  font-family: 'ElkjopSans', sans-serif;
  text-align: left;
  ${fluidRange('font-size', '16px', '20px')};
  ${fluidRange('line-height', '22px', '26px')};
  font-weight: 90;
  color: ${palette('white')};
`

const Button = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  ${fluidRange('height', '36px', '48px')};
  ${fluidRange('padding-right', '16px', '28px')};
  ${fluidRange('padding-left', '16px', '28px')};
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: ${palette('white')};
  color: ${palette('blue')};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;

  &:hover {
    background-color: ${palette('ui5Solid')};
    border-color: ${palette('ui20Solid')};
  }
`

const ImageWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  width: 68%;
  height: 100%;
  z-index: 10;

  @media (max-width: 768px) {
    width: 100%;
    height: 58%;
  }
`

const CardImage = styled(Image)`
  align-self: flex-end;
  width: 100%;
  max-height: 100%;
  border-radius: 8px;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
`

const OverlayShape = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  ${fluidRange('left', '-60px', '-180px')};
  ${fluidRange('bottom', '20px', '0px')};
`

interface LargeImageCardProps {
  image: Page_page_content_GridSystemRecord_card_LargeImageCardRecord_image
  title: string | null
  subtitle: string | null
  link: string | null
}

export default function LargeImageCard({
  image,
  title,
  subtitle,
  link,
}: LargeImageCardProps): JSX.Element {
  return (
    <CardWrapper>
      <Inner>
        <Content>
          <TextWrapper>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </TextWrapper>

          <Link href={link || '#'}>
            <Button>Sjá nánar</Button>
          </Link>
        </Content>

        <ImageWrapper>
          <CardImage
            data={image?.responsiveImage}
            layout="responsive"
            objectFit="contain"
            objectPosition="center"
          />
        </ImageWrapper>
      </Inner>

      <Overlay>
        <OverlayShape
          width="647"
          height="740"
          viewBox="0 0 647 740"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.1"
            d="M241.593 -51H-468V946.5H134C225.796 855.742 470.666 608.718 585.828 487.29C700.99 365.862 628.382 267.981 560.793 213.451C493.204 158.92 241.593 -51 241.593 -51Z"
            fill="white"
          />
        </OverlayShape>
      </Overlay>
    </CardWrapper>
  )
}
